import { Box, Flex, Container } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';

interface Props {
    [x: string]: string | number | ReactNode;
}

const Card: FC<Props> = (props) => {
    return (
        <Box
            className="o-bg"
            backgroundColor="#272145"
            flex={'1'}
            flexDirection={'column'}
            display={'flex'}
            justifyContent={
                props.justifyContent && props.justifyContent !== undefined
                    ? props.justifyContent
                    : 'center'
            }
            position={'relative'}
            alignItems={'center'}
            pt={props.pt && props.pt !== undefined ? props.pt : 5}
            pb={props.pt && props.pt !== undefined ? props.pt : 5}
        >
            <Container
                maxWidth={props.maxWidth ? props.maxWidth : 700}
                display={'flex'}
                justifyContent={'center'}
            >
                <Flex
                    alignItems={props.alignItems ?? undefined}
                    className="o-card"
                    bg={props.bg && props.bg !== undefined ? props.bg : 'primary'}
                    width={'100%'}
                    position="relative"
                    zIndex={1}
                    minHeight={'400px'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    pt={props.innerPt && props.innerPt !== undefined ? props.innerPt : 0}
                    pb={props.innerPb && props.innerPb !== undefined ? props.innerPb : 0}
                >
                    {props.children}
                </Flex>
            </Container>
        </Box>
    );
};

export default Card;
